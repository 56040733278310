<template>
  <div class="user-card-item">
    <div class="card-top">
      <div class="avatar">
        <a-avatar shape="square" :size="120" :src="item.img || defaultUser" />
      </div>
      <div class="user-detail">
        <div class="user-name">{{ formatName(item.realName) }}</div>
        <div class="user-skill" v-if="type === '2'">专业：{{ item.position }}</div>
        <div class="user-content">
          <div class="user-position">{{ type === '1' ? item.position : qualObj[item.qualificationCertificate] }}</div>
          <div class="user-line"></div>
          <div class="user-eval">{{ levelObj[item.skillLevelCertificate] }}</div>
        </div>
        <div class="user-skill" v-if="type === '1'">基本技能：{{ item.baseSkill }}</div>
        <div class="user-experienced">项目经历：{{ workObj[item.experience] }}</div>
      </div>
    </div>
    <!-- <div class="card-bottom">
      <div class="user-label-title">专业技能：</div>
      <div class="user-label">
        <div
          class="user-label-item"
          v-for="(data, index) in formatSkill(type === '1' ? item.professionalSkills : item.baseSkill)"
          :key="index"
        >
          {{ data }}
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import defaultUser from '@/assets/default-user.png'

const workObj = {
  1: '30万机组以下',
  2: '30-60万机组',
  3: '100万及以上机组'
}

const levelObj = {
  0: '无',
  1: '初级',
  2: '中级',
  3: '高级',
  '-1': '特殊工种证书'
}

const qualObj = {
  0: '无',
  1: '安全员',
  2: '建造师'
}
export default {
  name: 'ItemUser',
  props: {
    type: {
      type: String,
      default: '1'
    },
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      workObj,
      levelObj,
      qualObj,
      defaultUser
    }
  },
  methods: {
    formatName (name) {
      if (name.length === 2) {
        return name.substr(0, 1) + '*'
      } else if (name.length > 2) {
        var char = ''
        for (var i = 0, len = name.length - 2; i < len; i++) {
          char += '*'
        }
        return name.substr(0, 1) + char + name.substr(-1, 1)
      }
    },
    formatSkill (skills) {
      return skills ? skills.split('、') : []
    }
  }
}
</script>

<style lang="less">
.user-card-item {
  display: flex;
  margin: 0px 6px 15px;
  padding: 10px;
  background: #f7f9fa;
  border-radius: 10px;
  color: #666666;
  flex-direction: column;
  //   &:nth-child(3n) {
  //     margin-right: 0px;
  //   }
  .card-top {
    display: flex;
    align-items: center;
    .avatar {
      margin-right: 20px;
    }
    .user-detail {
      width: 186px;
      height: 120px;
      .user-name {
        font-weight: 400;
        font-size: 20px;
        color: #333333;
      }
      .user-content {
        display: flex;
        align-items: center;
        .user-line {
          height: 12px;
          width: 2px;
          margin: 0px 6px 0px 6px;
          background: #666666;
        }
      }
      .user-skill,
      .user-experienced,
      .user-content {
        margin-top: 8px;
        width: 95%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .user-eval {
        color: rgba(229, 24, 24, 1);
      }
    }
  }
  .card-bottom {
    border-top: 1px dashed #cfdde4;
    margin-top: 17px;
    padding-top: 12px;
    display: flex;
    align-items: center;
    .user-label-title {
      min-width: 70px;
    }
    .user-label {
      width: 276px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      &-item {
        background: rgba(22, 119, 255, 0.1);
        border-radius: 2px;
        padding: 2px 6px;
        margin-right: 6px;
        display: inline-block;
        color: #1677ff;
      }
    }
  }
}
</style>
